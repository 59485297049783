<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      min-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          style="background-color: #7253cf; color: white"
          :class="type.tab === 'distributedPrizes' ? 'pt-0 pb-4' : 'pb-0'"
        >
          <div class="d-flex">
            <img
              src="@/assets/sponsorModalLogo.svg"
              v-if="type.tab === 'distributedPrizes'"
              height="100"
              alt=""
            />
            <img src="@/assets/prizeBall.svg" v-else height="100" />
          </div>
          <div class="ml-4 mb-3 dialogTitle-text">
            {{ dialogTitle.title }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleAddEditViewPrizeModal({ show: false })"
            :style="
              type.tab === 'distributedPrizes'
                ? 'cursor: pointer; margin-top: -60px; margin-right: -12px'
                : 'cursor: pointer; margin-top: -80px; margin-right: -12px'
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="prizeForm" lazy-validation>
            <v-row v-if="type.tab === 'prizeMaster'" class="pt-10 pb-0">
              <v-col cols="6" v-if="imgPreview" class="py-0">
                <img :src="imgPreview" style="max-width: 200px" />
              </v-col>
              <v-col cols="6" class="pr-6 py-2 pb-3">
                <v-btn
                  text
                  dark
                  color="#38227A"
                  class="text-capitalize px-5"
                  style="
                    border: 1.5px solid #38227a;
                    border-radius: 10px;
                    font-family: Lato;
                    font-weight: 600;
                  "
                  @click="$refs.fileInput.click()"
                  height="37px"
                >
                  <span v-if="!imgPreview">Upload Image</span>
                  <span v-else>Change Image</span>
                </v-btn>
                <v-file-input
                  accept="image/png,image/jpeg,image/svg+xml"
                  label="upload logo"
                  prepend-icon
                  outlined
                  color="#7253CF"
                  dense
                  @change="onUploadLogoChange"
                  style="display: none"
                  id="inputId"
                >
                </v-file-input>
                <label
                  for="inputId"
                  ref="fileInput"
                  style="display: none"
                ></label>
              </v-col>
              <v-col cols="12" class="py-0">
                <div v-if="!imgPreview" class="imgInstruction">
                  Please upload a image for the prize, file size should be less
                  then 10 MB
                </div>
              </v-col>
              <div v-if="!imgPreview"><br /><br /><br /><br /></div>
              <v-col cols="12">
                <v-text-field
                  label="Prize Name"
                  outlined
                  dense
                  v-model="prizeName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="py-4"
              no-gutters
              v-if="type.tab === 'commonPrizes' || type.tab === 'customPrizes'"
            >
              <v-col cols="6" class="pr-5" v-if="type.tab === 'customPrizes'">
                <v-autocomplete
                  label="Select District"
                  outlined
                  dense
                  v-model="district"
                  :items="districtList"
                  item-value="id"
                  item-text="district_name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-5" v-if="type.tab === 'customPrizes'">
                <v-autocomplete
                  label="Select School"
                  outlined
                  dense
                  v-model="school"
                  :items="schoolList"
                  item-value="id"
                  item-text="school_name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="formTitle">Prize Type</div>
                <v-radio-group v-model="prizeType">
                  <v-radio
                    v-for="(item, i) in prizeTypeList"
                    :key="i"
                    color="#7253CF"
                    class="formFields"
                    :label="item.title"
                    :value="item.id"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="donationInputFlag">
                <v-text-field
                  outlined
                  dense
                  v-model="donationAmount"
                  label="Donation Amount"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="formTitle">Select Prizes to be given</div>
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="px-0 py-0"
                    v-for="(item, i) in checkboxItems"
                    :key="i"
                  >
                    <div style="height: 20px">
                      <v-checkbox
                        v-model="item.flag"
                        color="#7253CF"
                        class="formFields"
                        :label="item.name"
                        @change="checkbox(item.id, item.flag)"
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="type.tab === 'distributedPrizes'">
              <v-col cols="12" class="pt-10 pb-4">
                <v-autocomplete
                  label="Select School"
                  outlined
                  dense
                  v-model="distributionSchool"
                  :items="schoolList"
                  item-value="id"
                  item-text="school_name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-6 pt-0">
                <v-row style="background-color: #efefef">
                  <v-col cols="4" class="text-center">
                    <div class="headingModal">Prize</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <div class="headingModal"># of Units</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider style="height: 2px"></v-divider>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row v-for="(item, i) in pendingPrizeList" :key="i">
                  <v-col cols="4" class="text-center">
                    <div class="headingModal">{{ item.prize_name }}</div>
                    <div style="color: #757575">
                      ({{ item.prize_count }} pending)
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      v-model="item.quantity"
                      @change="storePrizeQuantity(item.prize_id, item.quantity)"
                      dense
                      color="#7253CF"
                      class="formFields"
                      type="number"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-2 pb-8">
          <v-btn
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText px-6 mr-2"
            height="43px"
            dark
            color="#38227A"
            @click="formSubmit"
          >
            <span>{{ dialogTitle.btnTitle }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_PRIZE_TYPE_MODAL,
  API_PRIZE_CHECKBOX_GET,
  API_ADMIN_GET_DISTRICT_LIST,
  API_ADMIN_GET_SCHOOL_LIST,
  API_PRIZE_MASTER_POST,
  API_PRIZE_COMMON_CUSTOM_POST,
  API_PRIZE_PENDING_LIST_GET,
  API_PRIZE_DISTRIBUTION_POST,
  API_PRIZE_DISTRIBUTED_GET_DETAIL,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "PrizeModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      imgPreview: null,
      LogoImgFile: {},
      prizeName: "",
      district: null,
      school: null,
      distributionSchool: null,
      districtList: [],
      schoolList: [],
      prizeType: "",
      prizeTypeList: [],
      donationAmount: null,
      checkboxItems: [],
      prizeToGiven: [],
      pendingPrizeList: [],
      pendingPrizeListTobeSend: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "prizeManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewPrizeModal({ show: value });
      },
    },
    type() {
      let type = this.$store.state.prizeManagement.addEditViewModal.type;
      let tab = this.$store.state.prizeManagement.addEditViewModal.tab;
      return { tab: tab, type: type };
    },
    dialogTitle() {
      switch (this.type.tab) {
        case "prizeMaster":
          return { title: "Add New Prize", btnTitle: "Add prize" };
        case "commonPrizes":
          return {
            title: "Add New Common Prize",
            btnTitle: "Add common prize",
          };
        case "customPrizes":
          return {
            title: "Add New Custom Prize",
            btnTitle: "Add custom prize",
          };
        case "distributedPrizes":
          if (this.type.type === "add") {
            return {
              title: "Add New Distribution Entry",
              btnTitle: "Add new record",
            };
          } else {
            return { title: "Edit distribution Entry", btnTitle: "Save" };
          }
        default:
          return "";
      }
    },
    donationInputFlag() {
      if (this.prizeType) {
        let prizeID =
          this.prizeTypeList[
            this.prizeTypeList.findIndex((item) => item.type === "donation")
          ].id;
        if (prizeID === this.prizeType) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        this.loading = true;
        setTimeout(() => {
          this.modalOpenCallback();
        }, 100);
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
    distributionSchool: function () {
      if (this.distributionSchool) {
        if (this.type.type === "add") {
          this.getPendingPrize();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewPrizeModal: "prizeManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    modalOpenCallback() {
      if (this.type.tab === "commonPrizes") {
        this.getPrizeType();
      } else if (this.type.tab === "customPrizes") {
        this.getDistrictList();
      } else if (this.type.tab === "distributedPrizes") {
        this.getSchoolList();
      } else if (this.type.tab === "prizeMaster") {
        this.loading = false;
      }
    },
    modalCloseCallback() {
      this.$refs.prizeForm.reset();
      this.loading = false;
      this.formLoading = false;
      //master prize form
      this.imgPreview = null;
      this.LogoImgFile = {};
      this.prizeName = "";
      this.district = null;
      this.school = null;
      this.districtList = [];
      this.schoolList = [];
      this.prizeType = "";
      this.prizeTypeList = [];
      this.donationAmount = null;
      this.checkboxItems = [];
      this.prizeToGiven = [];
      this.distributionSchool = null;
      this.pendingPrizeList = [];
      this.pendingPrizeListTobeSend = [];
    },
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      let file = payload; // in case vuetify file input

      if (file) {
        if (file.size < 1000000) {
          console.log(file.type);
          this.LogoImgFile = payload;
          this.imgPreview = URL.createObjectURL(file);
          URL.revokeObjectURL(file); // free memory
        } else {
          console.log("image validation error");
          this.showToast({
            message: "Image file size must be less than 10mb",
            color: "e",
          });
        }
      } else {
        this.imgPreview = null;
      }
    },
    getDistrictList() {
      const successHandler = (res) => {
        console.log(res.data);
        this.districtList = res.data.district_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchoolList();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchoolList() {
      const successHandler = (res) => {
        console.log(res.data);
        const data = res.data;
        this.schoolList = data.school_list;
        if (this.type.tab === "distributedPrizes") {
          if (this.type.type === "add") {
            this.loading = false;
          }
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        if (this.type.tab === "distributedPrizes") {
          console.log("do nothing initialy");
          if (this.type.type === "edit") {
            this.getDistributedDetail();
          }
        } else {
          this.getPrizeType();
        }
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getPrizeType() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.prizeTypeList = res.data.prize_type_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getPrizeListCheckbox();
      };
      let formData = {};
      Axios.request_GET(
        API_PRIZE_TYPE_MODAL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getPrizeListCheckbox() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data.prize_list);
        this.checkboxItems = res.data.prize_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        API_PRIZE_CHECKBOX_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getPendingPrize() {
      this.loading = true;
      const successHandler = (res) => {
        this.pendingPrizeList = res.data.school_list[0].prize_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["school_id"] = this.distributionSchool;
      Axios.request_GET(
        API_PRIZE_PENDING_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    storePrizeQuantity(id, quantity) {
      if (
        this.pendingPrizeListTobeSend.findIndex((obj) => obj.prize_count === id)
      ) {
        let item_obj = { prize_id: id, prize_count: quantity };
        this.pendingPrizeListTobeSend.push(item_obj);
      }
    },
    checkbox(id, flag) {
      if (flag) {
        this.prizeToGiven.push(id);
      }
      console.log(this.prizeToGiven, "prizeto given");
    },
    formSubmit() {
      this.formLoading = true;
      switch (this.type.tab) {
        case "prizeMaster":
          this.submitPrizeMaster();
          break;
        case "commonPrizes":
          this.submitCommonCustomPrize();
          break;
        case "customPrizes":
          this.submitCommonCustomPrize();
          break;
        case "distributedPrizes":
          if (this.type.type === "add") {
            this.submitDistributedPrize();
          } else {
            this.submitDistributedPrizePatch();
          }
          break;
        default:
          break;
      }
    },
    submitPrizeMaster() {
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.showToast({
          message: "Prize added successfully.",
          color: "s",
        });
        this.$emit("reload");
        this.toggleAddEditViewPrizeModal({ show: false });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = new FormData();
      formData.append("name", this.prizeName);
      formData.append("logo", this.LogoImgFile);
      formData.append("status", true);
      Axios.request_POST(
        API_PRIZE_MASTER_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitCommonCustomPrize() {
      const successHandler = (res) => {
        console.log(res);
        this.$emit("reload");
        this.toggleAddEditViewPrizeModal({ show: false });
        this.showToast({
          message:"Prize added successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      if (this.type.tab === "customPrizes") {
        formData["is_common"] = false;
        formData["school"] = this.school;
        formData["district"] = this.district;
      } else {
        formData["is_common"] = true;
      }
      formData["prize_type"] = this.prizeType;
      formData["prize"] = this.prizeToGiven;
      if (this.donationAmount) {
        formData["amount"] = this.donationAmount;
      }
      Axios.request_POST(
        API_PRIZE_COMMON_CUSTOM_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDistributedDetail() {
      this.loading = true;

      const successHandler = (res) => {
        this.pendingPrizeList = res.data.school_list[0].prize_list;
        this.distributionSchool = res.data.school_list[0].school_id;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["response_type"] = "distributed";
      formData["id"] =
        this.$store.state.prizeManagement.addEditViewModal.prizeID;
      Axios.request_GET(
        API_PRIZE_DISTRIBUTED_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitDistributedPrize() {
      const successHandler = (res) => {
        console.log(res);
        this.$emit("reload");
        this.toggleAddEditViewPrizeModal({ show: false });
        this.showToast({
          message: "Distribution record added successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["school"] = this.distributionSchool;
      formData["prize_list"] = this.pendingPrizeListTobeSend;
      Axios.request_POST(
        API_PRIZE_DISTRIBUTION_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitDistributedPrizePatch() {
      const successHandler = (res) => {
        console.log(res);
        this.$emit("reload");
        this.toggleAddEditViewPrizeModal({ show: false });
        this.showToast({
          message: "Distribution Record Updated",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["school"] = this.distributionSchool;
      formData["prize_list"] = this.pendingPrizeListTobeSend;
      formData["id"] =
        this.$store.state.prizeManagement.addEditViewModal.prizeID;
      Axios.request_PATCH(
        API_PRIZE_DISTRIBUTION_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.imgInstruction {
  font-family: Lato;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #404040;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.formTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.6);
}
.prizeSimpleTbHeader {
  background-color: #efefef;
}
.headingModal {
  color: #000000;
  font-size: 18px;
}
</style>
